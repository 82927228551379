:root {
    --white: #fff;
    --black: #000;
    --primary-blue-700: #002a70;
    --primary-blue-600: #0b5cd5;
    --primary-blue-500: #007dfa;
    --primary-blue-400: #80befc;
    --primary-blue-300: #cedff4;
    --primary-blue-200: #ebeef8;
    --primary-blue-100: #f7fbfc;
    --cautionary-orange-600: #c05203;
    --cautionary-orange-500: #f96c1c;
    --cautionary-orange-400: #ff9446;
    --cautionary-orange-300: #f39b16;
    --cautionary-orange-200: #ffeed9;
    --cautionary-orange-100: #fbf6f1;
    --negative-red-600: #a41207;
    --negative-red-500: #e02b1d;
    --negative-red-400: #e25044;
    --negative-red-300: #ff7c72;
    --negative-red-200: #fff1f0;
    --negative-red-100: #fff9f9;
    --gray-gray-600: #333;
    --gray-gray-500: #494949;
    --gray-gray-400: #7d7d7d;
    --gray-gray-300: #ddd;
    --gray-gray-200: #dcdfe3;
    --gray-gray-100: #f5f5f7;
    --primary-blue-500: #1678f3;
    --primary-blue-600: #0b5cd5;
    --gray-gray-200: #dcdfe3;
    --white: #fff;
    --gray-100: #f5f5f7;
    --gray-200: #dcdfe3;
    --black: #000;
    --white: #fff;
    --primary-blue-700: #002a70;
    --primary-blue-600: #0b5cd5;
    --primary-blue-500: #007dfa;
    --primary-blue-400: #80befc;
    --primary-blue-300: #cedff4;
    --primary-blue-200: #ebeef8;
    --primary-blue-100: #f7f9ff;
    --cautuionary-orange-600: #c05203;
    --cautuionary-orange-500: #f96c1c;
    --cautuionary-orange-400: #ff9446;
    --cautuionary-orange-300: #f39b16;
    --cautuionary-orange-200: #ffeed9;
    --cautuionary-orange-100: #fbf6f1;
    --nagative-red-600: #a41207;
    --nagative-red-500: #e02b1d;
    --nagative-red-400: #e25044;
    --nagative-red-300: #ff7c72;
    --nagative-red-200: #fff1f0;
    --nagative-red-100: #fff9f9;
    --gray-600: #333;
    --gray-500: #494949;
    --gray-400: #7d7d7d;
    --gray-300: #ddd;
    --theme-white: #fff;
  }